/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ClickhouseAnalyticsv1ShortVendor,
    RpcStatus,
    V1CreateReconciliationReportRequest,
    V1CreateReconciliationReportResponse,
    V1ListCurrenciesResponse,
    V1ListReconciliationReportsResponse,
    V1ListTZOffsetsResponse,
    V1Project,
    V1ReconciliationReport,
    V1ShortGame,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ClickhouseAnalytics<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags ClickhouseAnalyticsService
     * @name ClickhouseAnalyticsServiceListCurrencies
     * @request GET:/clickhouse-analytics/currencies
     * @secure
     */
    clickhouseAnalyticsServiceListCurrencies = (
        query?: {
            /** @format int64 */
            page_size?: number;
            /** @format int64 */
            page_number?: number;
            code_name?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListCurrenciesResponse, RpcStatus>({
            path: `/clickhouse-analytics/currencies`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ClickhouseAnalyticsService
     * @name ClickhouseAnalyticsServiceListProjects
     * @request GET:/clickhouse-analytics/projects
     * @secure
     */
    clickhouseAnalyticsServiceListProjects = (
        query?: {
            title?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1Project[], RpcStatus>({
            path: `/clickhouse-analytics/projects`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ClickhouseAnalyticsService
     * @name ClickhouseAnalyticsServiceListReconciliationReports
     * @request GET:/clickhouse-analytics/report-export-requests
     * @secure
     */
    clickhouseAnalyticsServiceListReconciliationReports = (
        query?: {
            /** @format int64 */
            page_size?: number;
            /** @format int64 */
            page_number?: number;
            sort_by_created_at?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListReconciliationReportsResponse, RpcStatus>({
            path: `/clickhouse-analytics/report-export-requests`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ClickhouseAnalyticsService
     * @name ClickhouseAnalyticsServiceCreateReconciliationReport
     * @request POST:/clickhouse-analytics/report-export-requests
     * @secure
     */
    clickhouseAnalyticsServiceCreateReconciliationReport = (
        body: V1CreateReconciliationReportRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1CreateReconciliationReportResponse, RpcStatus>({
            path: `/clickhouse-analytics/report-export-requests`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ClickhouseAnalyticsService
     * @name ClickhouseAnalyticsServiceGetReconciliationReport
     * @request GET:/clickhouse-analytics/report-export-requests/{id}
     * @secure
     */
    clickhouseAnalyticsServiceGetReconciliationReport = (
        id: string,
        params: RequestParams = {},
    ) =>
        this.request<V1ReconciliationReport, RpcStatus>({
            path: `/clickhouse-analytics/report-export-requests/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ClickhouseAnalyticsService
     * @name ClickhouseAnalyticsServiceListTzOffsets
     * @request GET:/clickhouse-analytics/time-zones
     * @secure
     */
    clickhouseAnalyticsServiceListTzOffsets = (params: RequestParams = {}) =>
        this.request<V1ListTZOffsetsResponse, RpcStatus>({
            path: `/clickhouse-analytics/time-zones`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ClickhouseAnalyticsService
     * @name ClickhouseAnalyticsServiceListVendorsWithTransactions
     * @request GET:/clickhouse-analytics/vendors
     * @secure
     */
    clickhouseAnalyticsServiceListVendorsWithTransactions = (
        query?: {
            b2b_project_ids?: string[];
            title?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<ClickhouseAnalyticsv1ShortVendor[], RpcStatus>({
            path: `/clickhouse-analytics/vendors`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ClickhouseAnalyticsService
     * @name ClickhouseAnalyticsServiceListGamesByVendor
     * @request GET:/clickhouse-analytics/vendors/{vendor_id}/games
     * @secure
     */
    clickhouseAnalyticsServiceListGamesByVendor = (
        vendorId: string,
        query?: {
            title?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ShortGame[], RpcStatus>({
            path: `/clickhouse-analytics/vendors/${vendorId}/games`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
}
