export const paths = {
    HOME: "/",

    AUTH_SIGN_IN: "/auth/sign-in",
    AUTH_EXPIRED_PASSWORD: "/auth/expired-password",
    AUTH_RESET_PASSWORD: "/auth/reset-password",
    AUTH_TWO_FACTOR_AUTHENTICATION: "/auth/two-factor-authentication",
    AUTH_INVITE_CONFIRM_TOKEN: "/auth/invite/confirm/[token]",
    AUTH_INVITE_PASSWORD_SET_TOKEN: "/auth/invite/password_set/[token]",

    ANALYTICS: "/analytics",
    ANALYTICS_REPORT_ID: "/analytics/report/[id]",

    AGG_FOLDERS_FOR_REPORTS: "/folders-for-reports/agg",

    B2B_COMPANIES: "/b2b-companies",
    GAME_ACCESS: "/game-access",
    B2B_COMPANIES_HIERARCHY: "/b2b-companies-hierarchy",
    B2B_COMPANIES_HIERARCHY_COMPANY_ID: "/b2b-companies-hierarchy/[companyId]",

    B2B_FOLDERS_FOR_REPORTS: "/folders-for-reports/b2b",

    CONNECTIONS: "/connections",

    GAMES: "/games",
    CREATE_NEW_GAME: "/games/create",
    EDIT_GAME: "/games/edit/[id]",

    ROLES: "/roles",
    ROLE_PERMISSIONS_ROLE_ID_EDIT: "/role-permissions/[roleId]/edit",
    ROLE_PERMISSIONS_ROLE_ID_CREATE: "/role-permissions/[roleId]/create",

    USERS: "/users",
    USER_TEST_PAGE: "/user-test-page",
    USERS_PERMISSIONS: "/users-permissions",
    USER_ACCESS_COMPANIES: "/user-access-companies",

    USER_ACCESS_TO_AGGREGATOR_REPORTS: "/user-access-to-reports/agg",
    USER_ACCESS_TO_B2B_REPORTS: "/user-access-to-reports/b2b",
    USER_ACCESS_TO_PROVIDER_REPORTS: "/user-access-to-reports/provider",

    B2B_USER_ACCESS_TO_REPORTS: "/b2b-user-access-to-reports",
    PROVIDER_USER_ACCESS_TO_REPORTS: "/provider-user-access-to-reports",

    HANDBOOKS: "/handbooks",

    B2B_COMPANY_REPORTS: "/company-reports/b2b",
    PROVIDER_COMPANY_REPORTS: "/company-reports/provider",

    PLATFORM_MONITORING: "/platform-monitoring",

    HANDBOOKS_TAGS_FOR_GAMES: "/handbooks/tags-for-games",
    HANDBOOKS_METRICS_FOR_ANALYTICS: "/handbooks/metrics-for-analytics",

    STANDARD_REPORTS_PROVIDER: "/standard-reports/provider",
    STANDARD_REPORTS_B2B: "/standard-reports/b2b",
    STANDARD_REPORTS_AGG: "/standard-reports/agg",

    PROVIDERS: "/providers",
    PROVIDER_FOLDERS_FOR_REPORTS: "/folders-for-reports/provider",

    AGGREGATOR_COMPANY_REPORTS_IN_FOLDERS_TREE:
        "/company-reports-in-folders-tree/agg",
    B2B_COMPANY_REPORTS_IN_FOLDERS_TREE:
        "/company-reports-in-folders-tree/b2b/[id]",
    PROVIDER_COMPANY_REPORTS_IN_FOLDERS_TREE:
        "/company-reports-in-folders-tree/provider/[id]",

    B2B_ANALYTICS_REPORTS: "/b2b-analytics/reports",

    PROVIDER_ANALYTICS_REPORTS: "/provider-analytics/reports",

    REPORT: "/report/[id]",

    STARTER_B2B: "/starter/b2b",

    REPORT_EXPORTS: "/report-exports",
};
